.navigation {
  height: 48px;
  background-color: white;
  z-index: 100;
  position: relative;

  &.fixed{
    position: fixed;
  }

  .noti-navigation{
    &[disabled] {
      opacity: 0.5;
      cursor: default;
    }
  }

  padding-top: 12px;
  padding-bottom: 8px;

  .logo {
    height: 28px;
  }

  .mask {
    display: none;
  }

  &[data-open] {
    .mask {
      display: block;
      @include stretch;
      background: rgba(0, 0, 0, 0.5);
      z-index: 2;
    }

    .menu-wrapper {
      display: flex;
      flex-direction: column;
      z-index: 3;
    }
  }

  .menu-wrapper {
    display: none;
    background-color: white;
    position: absolute;
    right: 0;
    top: -12px;
    width: 75%;
    padding: 0;

    &> :not(.sm-wide) {
      padding: 0 16px;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    .current-user {
      order: -1;

      .current-user-content {
        height: 47px;
        line-height: 47px;
        flex-wrap: nowrap;
        display: flex;
        align-items: center;
        padding: 0 12px;

        &>* {
          vertical-align: middle;
        }

        .tagozat {
          height: 47px;
        }
      }

      &:after {
        content: "";
        display: block;
        border-bottom: 1px solid #d8d8d8;
        width: 100%;
      }

      .name {
        font-size: 12px;
        display: inline-block;
        line-height: normal;
        flex-grow: 1;
        text-align: right;
        margin-left: 12px;
        margin-right: 12px;
        text-transform: uppercase;
      }
    }

    .main-menu {
      a:hover:not(.active){
        border-bottom: 4px solid rgba(#b7a887, 0.7);
      }
      .active {
        border-bottom: 4px solid #b7a887;
        @include font(condbold);
      }
    }

    .menu-buttons {
      margin-top: 23px;
      
      &.gray{
        margin-top: 0;
        padding-top: 17px;
        padding-bottom: 17px;
        background: rgba(0, 0, 0, 0.08);
        a{
          color: rgba(0, 0, 0, 0.8);
          line-height: 32px;
          text-decoration: none;
          display: block;
        }
      }

      .btn {
        display: block;
        min-height: auto;
        margin-bottom: 17px;
        letter-spacing: 0.5px;
        @include font(cond);

      }
    }
  }

  .main-menu li,
  .menu-item {
    line-height: 47px;
    font-size: 20px;
    @include font(cond);
  }

  .notification-icon {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
  }

  .notification-count {
    background-color: #b7a887;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    line-height: 13px;
    font-size: 9px;
    color: white;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
  }

  @include media-breakpoint-up(lg) {
    height: 64px;

    padding-top: 16px;
    padding-bottom: 16px;

    &[data-open] {
      .mask {
        display: none;
      }
      .menu-wrapper {
        flex-direction: row;
      }
    }

    &>.container-fluid>.row {
      display: flex;

      .col {
        max-width: none;
      }

      .logo {
        max-width: 130px;
        height: 32px;
      }
    }

    ul {
      display: inline;
    }

    li {
      display: inline;
    }

    .menu-wrapper {
      display: flex;
      position: static;
      flex-grow: 1;

      &>:not(.sm-wide) {
        padding: 0;
      }

      .main-menu {
        order: 1;
        height: 32px;

        li {
          vertical-align: middle;
        }
      }

      .menu-buttons {
        order: 2;
        flex-grow: 1;
        text-align: right;
        margin-top: 0;
        height: 32px;

        li {
          vertical-align: middle;
          height: 32px;
          display: inline-block;
        }

        .btn {
          display: inline-block;
          border-width: 0;
          padding: 6px 2em;
          margin-bottom: 0;

        }
      }

      .notifications {
        order: 3;
        height: 32px;

        a {
          line-height: normal;
        }
      }

      .current-user {
        order: 4;
        height: 32px;
        cursor: pointer;
        

        &:after {
          display: none;
        }
        &:hover{
          .current-user-content {
            .name{
              opacity: 0.7;
            }
          }
        }

        .current-user-content {
          height: 32px;
          line-height: 32px;
          padding-left: 0;

          .name {
            margin-left: 0;
            max-width: 100px;
            transition: opacity 0.2s;
          }

          .tagozat {
            height: 42px;
          }
        }
      }

      .submenu {
        display: none;
      }
    }

    .main-menu li,
    .menu-item {
      line-height: normal;
      font-size: 16px;
      padding: 0 17px;
    }
  }


  .main-menu li,
  .navigation .menu-item {
    @media (min-width: 992px) {
      font-size: 14px;
      padding: 0 2px;
    }

    @media (min-width: 1012px) {
      padding: 0 6px;
    }

    @media (min-width: 1130px) {
      font-size: 16px;
      padding: 0 10px;
    }
  }
}

.submenu {
  background-color: rgba(0, 0, 0, 0.08);
  padding: 16px;
  color: rgba(0, 0, 0, 0.8);
  opacity: .5;

  li {
    height: 32px;
    line-height: 32px;
  }
}

.overlay-open{
  .submenu{
    display: block;
    position: absolute;
    top: 60px;
    right: 16px;
    background-color: white;
    opacity: 1;
    width: 137px;
    font-size: 13px;
    text-align: center;
    padding: 10px;
    
    &.noti{
      width: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      @include media-breakpoint-up(lg) {
        width: 345px;
        right: 24px;
        left: auto;
        transform: translate(0, 0);
      }
    }
  
    a {
      color: #666666;
      transition: opacity 0.2s;
      &:hover{
        opacity: 0.7;
      }
    }
  
    @include shadow(8);
  
    li {
      display: block;
      border-bottom: 1px solid rgba(183, 168, 135, 0.5);
      line-height: 38px;
      height: 38px;
  
      &:last-child {
        border-bottom: none;
      }
    }
  }
}


[data-toggle-target="noti"] {
  @include media-breakpoint-up(lg) {
    .decor {
      opacity: 0;
      display: block;
      position: fixed;
      background-color: white;
      transform: rotate(45deg);
      width: 18px;
      height: 18px;
      top: 64px;
    }
  }
  
  &.overlay-open{
    .decor{
      opacity: 1;
    }
  }
}
