.attachment-box{
  background: $gray-200;
  box-shadow: 8px 8px 0 0 rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-right: 8px;
  .row{
    margin-bottom: 0;
  }
  h3{
    font-size: 16px;
    margin-bottom: 15px;
  }
  p{
    font-size: 13px;
  }
  small{
    display: block;
    position: relative;
    font-size: 10px;
    margin-bottom: 15px;
    &.info{
      padding-left: 25px;
      img{
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  .file-list{
    padding-bottom: 10px;
    .file{
      position: relative;
      display: flex;
      align-items: flex-end;
      padding-left: 30px;
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
      .file-icon{
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 0;
        background: transparent url(/static/img/ico-file-dark.svg) no-repeat 0 0;
        transform: translate(0, -43%);
      }
      span:not(.dot-loader){
        font-size: 12px;
        &.line{
          height: 8px;
          background: $gray-500;
        }
      }
      .details{
        flex: 1 1 auto;
        .file-info{
          position: relative;
          display: flex;
          padding-bottom: 3px;
          justify-content: space-between;
        }
        .file-name{
          &.error {
            color: red;
          }
        }
        .line{
          background: $gray-500;
          height: 8px;
        }
        .state{
          & > span{
            position: absolute;
            right: 0;
            top: -5px;
            display: none;
          }
        }
      }
      .action{
        flex: 0 1 80px;
        padding-left: 10px;
      }

      .btn-text{
        position: absolute;
        text-align: left;
        bottom: -3px;
        text-decoration: underline;
      }


      &[data-file-state=loading]{
        .btn-text{
          color: $gray-400;
        }
        .details{
          .state{
            .dot-loader{
              right: 3px;
              top: -15px;
              display: inline-block;
              span{
                font-size: 40px;
              }
            }
          }
        }
      }

      &[data-file-state=complete]{
        .btn-text{
          color: $dark;
          bottom: -7px;
        }
        .details{
          .line{
            background: $secondary;
          }
          .state{
            .complete{
              display: inline-block;
            }
          }
        }
        .file-icon{
          background-image: url(/static/img/ico-file-gold.svg);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    small{
      &.info{
        top: -5px;
        margin-bottom: 0;
      }
    }
  }
}
