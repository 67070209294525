@import '_reset';
@import '_boostrap';
@import '_utility';
@import '_font';
@import '_typography';

@import '_button';
@import '_form';

@import '_base';
@import '_reg';
@import '_homepage';
@import '_event';
@import '_login';
@import '_error';
@import '_profile';
@import '_expert';

@import 'components/_reg-step';
@import 'components/_overlay';
@import 'components/_event-card';
@import 'components/_szakember-card';
@import 'components/_attachment';
@import 'components/_file-loader';
@import 'components/_footer';
@import 'components/_navigation';
@import 'components/_user-data';
@import 'components/_datepicker';
@import 'components/_responsive-table';
@import 'components/_simplebar';
@import 'components/_parallax';
@import 'components/_decor-lines';
@import 'components/_notification';
@import 'components/_breadcrumb';

pre {
  border: 1px solid black;
  background: $white;
  padding: 0 7px 12px 7px;
  margin: 0;
}

.test{
  display: flex;
  div {
    flex: 1 1 auto;
  }
}
