[class*=fs]{
  line-height: 1.2!important;
}

$font-sizes: 12 13 14 16 18 20 22 24 26 28 30 35 40 45 50 60;

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size in $font-sizes {
      //@debug '$size ' + $size;

      .fs#{$infix}-#{$size} {
        font-size: $size#{px} !important;
      }
    }
  }
}

$text-alt-color: rgba(0, 0, 0, 0.5);

.text-alt {
  color: $text-alt-color;
}

.underline, a.underline {
  text-decoration: underline;
}

h1,h2,h3,h4,h5,h6,
span,strong,small,
p, button, a {
  line-height: 1.2;
}

.text-gray{
  color: $gray-200!important;
}

.text-dark-gray{
  color: $gray-400!important;
}
