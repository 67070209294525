.reg-step-row{
  position: relative;
  z-index: 3;
  &.expert-reg-step{
    .line{
      margin: 0 20px;
    }
  }
  .sticky{
    &.fixed{
      position: fixed;
      width: 100%;
      top: 0;
      
    }
  }
  .sticky-wrapper{
    padding-top: 36px;
    max-width: 336px;
    margin: 0 auto;
    background: $white;
    @include media-breakpoint-up(lg) {
      max-width: 100%;
    }
  }
}

.reg-step{
  margin: auto;
  .eq0,
  .eq1 {
    display: flex;
    margin: 0 auto;
    max-width: 336px;
    background: $white;
  }
  
  .eq0 {
    padding-bottom: 10px;
  }
  
  .eq1{
    background: $white;
  }
  
  .steps {
    display: flex;
    flex: 1 1 100%;
    align-items: center;
    justify-content: space-evenly;
    border: 4px solid $secondary;
    background: $white;
    min-height: 64px;
  }
  
  .step-container{
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
  }
  
  .line{
    position: relative;
    &:after {
      display: block;
      position: absolute;
      top: -20px;
      content: '';
      height: 40px;
      border-left: 1px solid $gray-300;
    }
  }
  
  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    min-height: 32px;
    background: $gray-300;
    border-radius: 50%;
    border: 2px solid $gray-300;
    color: $white;
    &.active{
      border-color: $secondary;
      color: $secondary;
      background: $gray-200;
    }
    &.complete{
      font-size: 0;
      background: $secondary;
      border-color: $secondary;
      &:after{
        content: '';
        display: block;
        width: 7px;
        height: 13px;
        margin-top: -2px;
        border-bottom: 2px solid $white;
        border-right: 2px solid $white;
        transform: rotate(40deg);
      }
    }
  }
  
  h2 {
    font-size: 19px;
    line-height: 1.2;
    text-align: right;
  }
  
  p {
    display: none;
  }
  
  @include media-breakpoint-up(lg) {
    border: 4px solid $secondary;
    padding: 20px;
    display: flex;
    padding: 16px 8px;
    margin-bottom: 0;
    @include shadow();
    
    .eq0,
    .eq1{
      width: auto;
      flex: 0 1 auto;
      margin: 0;
    }
    
    .eq0{
      margin: 0;
      flex-direction: column;
    }
    
    .eq1{
      border: none;
      max-width: none;
      align-items: flex-start;
      flex: 1 1 auto;
      .line:after{
        top: -5px;
        height: 75px;
      }
    }
    
    .steps{
      border: none;
      margin: 0 8px 0 8px;
      align-items: flex-start;
    }
    
    .step{
      margin-bottom: 5px;
    }
    
    h2 {
      font-size: 20px;
      white-space: nowrap;
    }
    
    .step-container{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    
    p {
      display: flex;
      width: 100%;
      font-size: 10px;
      justify-content: center;
      text-align: center;
      color: $gray-400;
    }
  }
}

.logo{
  flex: 1 1 auto;
}
