.row{
  &.szakemberek{
    @include media-breakpoint-up(sm) {
      margin-left: -22px;
      margin-right: -22px;
    }
  }
}

.szakember-card {
  flex: 0 0 100%;
  @include media-breakpoint-up(sm) {
    flex: 0 0 50%;
  }
  @include media-breakpoint-up(md) {
    flex: 0 0 33.3333%;
  }
  @include media-breakpoint-up(lg) {
    flex: 0 0 25%;  
  }
  
  &.empty{
    .szakember-frame{
      height: 258px;
    }
  }
  
  &.simple{
    display: block;
    text-decoration: none;
    border-bottom: 1px solid $secondary;
    &, &:hover{
      
    }
    .title{
      text-align: left;
    }
    .szakember-frame{
      align-items: center;
      display: flex;
      margin: 0;
      padding: 10px;
      background: none;
      box-shadow: none;
      
    }
    .photo{
      width: 43px;
      height: 43px;
      border-width: 2px;
    }
    .body{
      margin-left: 10px;
    }
    p{
      font-size: 12px;
      &.cond.strong{
        @include font();
      }
      &.position{
        margin-top: 0;
        padding-top: 0;
        border-top: none;
        text-align: left;
        min-height: auto;
        text-transform: none;
      }
    }
    
  }
  
  &.add {
    transition: opacity 0.2s;
    &:hover{
      opacity: 0.7;
    }
    .szakember-frame{
      border: 4px solid $secondary;
    }
  }
  
  .szakember-frame{
    background-color: white;
    margin-top: 20px;
    padding: 32px;
    @include media-breakpoint-up(sm) {
      margin: 20px 10px 0 10px;
    }
  }
  
  .title {
    color: #b7a887;
    font-size: 12px;
  }
  
  .position {
    border-top: 1px solid rgb(216, 216, 216);
    margin-top: 10px;
    padding-top: 10px;
    color: $text-alt-color;
    text-transform: uppercase;
    font-size: 14px;
    min-height: 28px;
  }
  
  &:hover{
    p.cond.strong{
      opacity: 0.5;
    }
  }
  
  p.cond.strong{
    transition: opacity 0.5s;
    opacity: 1;
  }
  
  p.new-text{
    font-size: 18px;
    @include font(condbold);
    color: $secondary;
    padding-top: 20px;
    min-height: 62px;
  }

}

.photo,
.new {
  width: 120px;
  height: 120px;
  &.sm{
    width: 40px;
    height: 40px;
    border-width: 2px;
  }
  &.md{
    width: 64px;
    height: 64px;
  }
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 4px solid #b7a887;
  display: inline-block;
  
  &.new{
    border: none;
  }
}
