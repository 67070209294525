.responsive-table {
  width: 100%;
  border-collapse: collapse;
  thead {
    tr {
      th{
        @include font(condbold);
        font-weight: normal;
        padding: 10px;
        box-shadow: 8px 8px 0 0 rgba(0, 0, 0, 0.1);
        background-color: #f4f4f4;
        text-align: left;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        &:last-child{
          border-right: none;
        }
      }
    }
  }
  .user-document{
    strong{
      @include font(cond);
      text-decoration: underline;
    }
  }
  @include media-breakpoint-down(md) {
    display: block;
    thead {
      display: none;
    }
    tbody{
      &, tr {
        display: block;
      }
      tr {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        &:last-child{
          margin-bottom: 0;
          border-bottom: none;
        }
        td{
          display: flex;
          flex: 0 1 100%;
          margin-bottom: 12px;
          div{
            font-size: 13px;
            padding: 10px;
            &:first-child{
              box-shadow: 8px 8px 0 0 rgba(0, 0, 0, 0.1);
              background-color: #f4f4f4;
              @include font(condbold);
              flex: 0 0 100px;
              
            }
            &:last-child{
              padding-left: 20px;
              @include font(cond);
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    tbody{
      tr{
        &:first-child{
          td{
            padding-top: 30px;
          }
        }
        td{
          padding: 15px 10px;
          vertical-align: middle;
          div{
            position: relative;
            font-size: 13px;
          }
          div.th{
            display: none;
          }
          div.td{
            &:after{
              content: '';
              display: block;
              position: absolute;
              width: 1px;
              height: 100%;
              right: -10px;
              top: -10px;
              box-sizing: content-box;
              padding: 10px 0;
              background: rgba(0, 0, 0, 0.2);
            }
          }
          &:last-child{
            div.td{
              &:after{
                display: none;
              }
            }
          }
        }
      }
    }
  }
  
  &.expert-skill-white{
    thead {
      tr {
        th{
          background: $white;
        }
      }
    }
    
    tbody {
      tr{
        td{
          padding: 0;
          div.td{
            padding: 10px;
            margin: 5px 0;
            background: $white;
            min-height: 44px;
            &:after{
              right: 0;
              top: 0;
              bottom: 0;
              padding: 0;
            }
          }
        }
        &:first-child{
          td{
            div.td {
              margin-top: 20px;
            }
          }
        }
      }
    }
    
    @include media-breakpoint-down(md) {
      tbody{
        tr {
          td{
            background: $white;
            div.th{
              box-shadow: none;
              background: $white;
              border-right: 1px solid rgba(0, 0, 0, 0.2);
            }
            div.td{
              margin-bottom: 0;
              min-height: auto;
              background: none;
            }
          }
          &:first-child{
            td{
              div.td {
                margin-top: 0;
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
    @include media-breakpoint-up(lg) {
      tbody {
        tr {
          td {
            &:nth-child(3) {
              max-width: 260px;
            }
          }
        }
      }
    }
    
  }
}
