.breadcrumb{
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
    position: absolute;
    top: 20px;
    left: 40px;
    background: $white;
    @include shadow(8);
    & > div{
      padding: 5px 10px;
      border-right: 1px solid $gray-300;
      &:last-child{
        border-right: none;
      }
      span, a{
        font-size: 13px;
        @include font ();
        text-decoration: underline;
        color: rgba(0,0,0,0.5);
      }
      span{
        @include font (condbold);
        text-decoration: none;
      }
      a{
        transition: opacity 0.2s;
        &:hover{
          opacity: 0.7;
        }
      }
    }
  }
}
