.user-data {
  margin-bottom: 20px;
  &:last-child {
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
  .label{
    display: block;
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 5px;
    padding: 0;
    color: rgba(0, 0, 0, 0.4);
  }
  
  .value{
    strong{
      display: block;
      font-size: 16px;
      line-height: 1.2;
    }
    a{
      color: $secondary;
      text-decoration: underline;
      &.simple{
        color: $dark;
      }
    }
  }
}

.user-data-row{
  margin-bottom: 0;
  [class*="col"]{
    &:first-child{
      border-bottom: 1px solid $gray-300;
      margin-bottom: 20px;
    }
  }
  @include media-breakpoint-up(lg) {
    [class*="col"]{
      padding-top: 20px;
      border-top: 1px solid $gray-300;
      &:first-child{
        border-bottom: none;
        border-right: 1px solid $gray-300;
        margin-bottom: 0;
      }
    }
  }
}

.user-document-list{
  display: flex;
  flex-wrap: wrap;
  .user-document{
    margin-bottom: 10px;
    width: 100%;
    transition: opacity 0.2s;
    &:hover{
      span.detail{
        opacity: 0.7;
      }
    }
    @include media-breakpoint-up(sm) {
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      width: 33%;
    }
  }
}

.user-document{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  word-break: break-all;
  
  .detail{
    padding-left: 5px;
    padding-right: 20px;
    strong, span{
      font-size: 13px;
      display: block;
      color: $gray-400;
    }
    strong{
      color: $dark;
    }
    span{
      padding-top: 3px;
    }
  }
}
