footer {
  position: relative;
  z-index: 1;
  background: url("/static/img/footer_bg.png") top center no-repeat #333;
  background-size: 100%;

  padding: 120px 0 32px 0;
  margin-top: 100px;

  * {
    color: white;
    font-size: 12px;
  }

  .contact-detail {
    line-height: 26px;
  }

  .content>.row:last-child {
    margin-top: 68px;
  }

  .copy {
    opacity: 0.5;
    margin-top: 32px;
  }

  h5 {
    color: white;
    font-size: 22px;
  }

  h6 {
    color: #b7a887;
    font-size: 18px;
    font-family: inherit;
    margin-top: 30px;
  }

  .col {
    color: white;
  }

  .logo h1 {
    color: white;
  }

  @include media-breakpoint-up(md) {
    h6 {
      margin-top: 0;
    }

    .content>.row:last-child {
      margin-top: 136px;
    }

    .copy {
      margin-top: 0;
      line-height: 32px;
    }
  }
}

.debug .timing {
  position: fixed;
  z-index: 1000;
  top: 90px;
  right: 0;
  background: rgba(255,255,255,0.5);
  padding: 1ex;
  cursor: pointer;

  .icon {
    display: inline;
    vertical-align: -1px;
    svg {
      height: 1em;
    }
  }

  dt {
    display: inline;
  }

  dd {
    display: inline;
    margin-left: 1ex;
  }
}
