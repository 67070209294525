.homepage {
  background-color: rgb(244, 244, 244);

  .cover-box{
    margin-bottom: -40px;
    @include media-breakpoint-up(lg) {
      margin-bottom: -80px;
    }
  }

  .section-header {
    .more {
      position: absolute;
      right: 12px;
      top: 30px;
      width: auto;
      padding-left: 2em;
      padding-right: 2em;
    }

    h2 {
      font-size: 31px;
    }

    @include media-breakpoint-up(md) {
      h2 {
        font-size: 64px;
      }
    }
  }

  .summary {
    .figure {
      border-left: 1px solid rgba(183, 168, 135, 0.5);
      min-width: 184px;
      font-size: 13px;

      &>* {
        font-weight: bold;
      }
    }

    .btn {
      white-space: nowrap;
    }

    .org-list{
      min-width: 220px;
      .org-box{
        @include media-breakpoint-up(lg) {
          flex: 0 0 33%;
          margin: auto;
          max-width: 33%;
        }
      }
    }

    .text{
      font-weight: normal;
      padding-top: 6px;
    }

    @include media-breakpoint-up(lg) {
      .dcoll {
        padding: 11px;
        min-height: 88px;

        &:last-child {
          min-height: auto
        }
      }
    }
  }

  .status-bar {
    .photo{
      width: 40px;
      height: 40px;
      margin: 10px 0;
      @include media-breakpoint-up(lg) {
        width: 64px;
        height: 64px;
        margin: 0;
      }
    }
  }

  .events {
    position: relative;
  }
}
