body.reg {
  .body-bg {
    background: transparent url(/static/img/bg-reg.jpg);
    background-size: cover;
  }
  .content {
    @include media-breakpoint-up(lg) {
      margin-top: 80px;
      margin-bottom: 55px;
    }
  }

  .custom-header {
    position: relative;
    padding-bottom: 40px;
    background-image: url(/static/img/artboard-1.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .row {
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
    &.m-negative {
      margin-bottom: -32px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 40px;
      }
    }
  }

  h1.reg {
    font-size: 28px;
    line-height: 33px;
    padding: 48px 10px 20px 10px;
    font-weight: normal;
  }
  .triangle {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin: 0.5em 0 1em 0;
  }
  .triangle::before {
    content: "‣";
    font-size: 64px;
    color: #b7a887;
    line-height: 22px;
    margin-right: 16px;
  }
  em {
    font-style: normal;
    color: #b7a887;
  }
  .subtitle {
    text-transform: uppercase;
    margin: 2em 0;
  }
}
