.noti{

}

.noti-actions {
  border-bottom: none !important;
  text-align: right;
}

.noti-row{
  display: flex;
  margin-bottom: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
  &[data-prevent] {
    cursor: default;
    .noti-icon{
      background-color: $dark;
    }
  }

  &[data-read]{
    .noti-icon{
      background-color: #d8d8d8;
      &[data-type="1"]{
        background-image: url(/static/img/notification/notification-icon-34-dp-szakember-dark.svg);
      }
      &[data-type="2"]{
        background-image: url(/static/img/notification/notification-icon-34-dp-esemeny-dark.svg);
      }
      &[data-type="3"]{
        background-image: url(/static/img/notification/notification-icon-34-dp-hs-dark.svg);
      }
      &[data-type="4"]{
        background-image: url(/static/img/notification/notification-icon-34-dp-szervezet-dark.svg);
      }
    }
  }
}

.noti-desc{
  flex: 1 0 auto;
  width: 265px;

  &, span, strong{
    display: block;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
  }

  strong{
    padding: 10px 0 0 0;
  }
}

.noti-icon{
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $secondary;
  box-shadow: inset 0px 0px 0px 2px rgba(0,0,0,0.1);
  margin-right: 10px;
  &[data-type="1"]{
    background-image: url(/static/img/notification/notification-icon-34-dp-szakember-white.svg);
    &[data-read]{
      background-image: url(/static/img/notification/notification-icon-34-dp-szakember-dark.svg);
    }
  }
  &[data-type="2"]{
    background-image: url(/static/img/notification/notification-icon-34-dp-esemeny-white.svg);
    &[data-read]{
      background-image: url(/static/img/notification/notification-icon-34-dp-esemeny-dark.svg);
    }
  }
  &[data-type="3"]{
    background-image: url(/static/img/notification/notification-icon-34-dp-hs-white.svg);
    &[data-read]{
      background-image: url(/static/img/notification/notification-icon-34-dp-hs-dark.svg);
    }
  }
  &[data-type="4"]{
    background-image: url(/static/img/notification/notification-icon-34-dp-szervezet-white.svg);
    &[data-read]{
      background-image: url(/static/img/notification/notification-icon-34-dp-szervezet-dark.svg);
    }
  }
}




