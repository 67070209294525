body[class*=expert] {
  &.list {
    background: $gray-200;
  }
  .login-box {
    [class*=col] {
      background: $white;
    }
  }
  
  .skill-list{
    .attachment-box .file-list{
      margin-bottom: 20px;
      &:empty{
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
    .skill{
      &:first-child{
        i.cross{
          display: none;
        }
      }
    }
  }
}

.row{
  .skill{
    &.simple{
      .attachment-box{
        padding-bottom: 30px;
      }
    }
  }
}
