* {
  box-sizing: border-box;
  flex-shrink: 1;
  color: $dark;
}

html{
  min-height:100%;
  position:relative;
}
body{
  height:100%;
  &.gray{
    background: $gray-200;
  }
  
  &.overflow-hidden{
    overflow: hidden;
  }
}

.debug{
  position: relative;
  overflow: hidden;
}

img{
  &.full{
    display: block;
    width: 100%;
    height: auto;
  }
}

a, button{
  &.simple:not(.szakember-card){
    text-decoration: underline;
    transition: opacity 0.2s;
    &:hover{
      opacity: 0.7;
    }
  }
}

a{
  &[data-prevent] {
    cursor: default;
  }
}

a.text-secondary {
  transition: opacity 0.2s;
  &:hover{
    color: $secondary!important;
    opacity: 0.7;
  }
  &:active,
  &:visited{
    opacity: 0.9;
  }
}

.content{
  position: relative;
  z-index: 1;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    width: 1056px;
    max-width: 100%;
    margin: 0 auto;
  }
  
}

.container-fluid{
  &.bg {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 0;
    .row{
      height: 100%;
    }
    [class*=col]{
      height: 100%;
      &:after {
        content: '';
        display: block;
        height: 100%;
        background: $white;
      }
    }
    &.shadowed{
      box-shadow: none;
      [class*=col]{
        &:after{
          box-shadow: 8px 8px 0 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  
  &.vertical-space{
    .row {
      margin-bottom: 20px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 40px;
      }
    }
  }
}

.row {
  @include media-breakpoint-down(md) {
    &.full{
      margin: 0 -24px;
    }
  }
}

[class*=container]{
  position: relative;
  z-index: 1;
}

.circle{
  position: relative;
  display: inline-block;
  width: 128px;
  max-width: 100%;
  .circle-icon{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50%;
    height: auto;
    max-width: 70%;
    transform: translate(-50%, -50%);
    &.sign-in{
     left: 55% 
    }
    &.scan{
      width: 33%;
    }
  }
  &:after{
    content: '';
    display: block;
    padding-bottom: 100%;
    background: $dark;
    border-radius: 50%;
  }
}

.triangle-left-secondary{
  @include triangle(left, $secondary, 15px, 18px);
}

.triangle-right-secondary{
  @include triangle(right, $secondary, 15px, 18px);
}

.bg-light{
  padding: 12px 20px;
}


.logo{
  display: flex;
  align-items: center;
  h1 {
    font-size: 10px;
    line-height: 1.2;
    color: $secondary;
    margin: -2px 0 0 5px
  }
}

.components-collection{
  h2{
    margin-bottom: 10px;
    border: 5px solid $dark;
    display: inline-block;
    padding: 5px;
    &:after{
      content: '';
      display: block;
    }
  }
  
  .row:not(:last-child){
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $dark;
  }
}

.img-box{
  position: relative;
  width: 100%;
  padding-top: 9/16*100%;
  background: $gray-200;
  .content{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 100%;
    padding: 12px;
  }
}

.buttons{
  display: flex;
  justify-content: space-between;
}

@include media-breakpoint-down(md) {
  .scroll-wrapper{
    opacity: 0;
    margin-left: -12px;
    margin-right: -12px;
    &.inited{
      opacity: 1;
    }
    position: relative;
    overflow: hidden;
    .scroll-content{
      overflow: hidden;
      margin-bottom: -20px;
    }
    .scroll{
      position: relative;
      padding-bottom: 20px;
      overflow-x: scroll; 
      & > div {
        margin-left: 0;
        margin-right: 0;
        &.w-150{
          width: 150%;
        }
      }
    }
  }
}

.decor-bg{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  transform: translate(0, 50%);
  user-select: none;
}

img[class*=-block]{
  width: 100%;
  height: auto;
}

#__bs_notify__{
  top: auto!important;
  right: auto!important;
  left: 0;
  bottom: 20px;
}

.inner-shadow{
  position: relative;
  &:before{
   content: '';
   @include stretch;
   background: 0;
   position: absolute;
   box-shadow: inset 8px 8px 0 0 rgba(0, 0, 0, 0.1); 
   user-select: none;
  }
}

.body-bg{
  @include stretch;
  z-index: -1;
  background: none;
}

.cover-box{
  position: relative;
  z-index: 1;
  //padding-top: calc(386/1367*100) + '%';
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 210px;
  margin-bottom: -35px;
  @include media-breakpoint-up(lg) {
    height: 456px;
    margin-bottom: -80px;
  }
}

a.news-card{
  display: block;
  transition: opacity 0.2s;
  &:hover{
    .btn{
      background-color: rgba($primary, 0.7);
    }
  }
  &:active,
  &:visited{
    .btn{
      background-color: rgba($primary, 0.9);
    }
  }
  .news-photo{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: calc(9/16*100) + '%'; 
  }
}

.news-card{
  position: relative;
  padding-bottom: 60px;
  background: $white;
  @include shadow(8);
  .btn{
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}


.profile-detail{
  position: relative;
  z-index: 1;
  margin-top: -100px;
  .profile-box{
    .profile-pic{
      width: 150px;
      margin: 0 auto;
      img{
        width: 100%;
        height: auto;
      }
    }
    
    .btn{
      top: 160px;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: -180px;
    .profile-box{
      margin-bottom: 60px;
      .profile-pic{
        width: 225px;
      }
    }
  }
}

.org-list{
  display: flex;
  flex-wrap: wrap;
  .org-box{
    img {
      display: block;
      margin: 0 auto;
      width: 32px;
      height: auto;
    }
    span{
      display: block;
      width: 70px;
      text-align: center;
      padding: 0 5px;
      font-size: 9px;
      word-break: break-all;
    }
  }
}

.break{
  word-break: break-all;
}

.decor-header {
  position: relative;
  z-index: 1;
  background-color: $secondary;
  background-image: url(/static/img/artboard-1.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 150px;
  @include media-breakpoint-up(lg) {
    min-height: 300px;
  }

  .decor-content{
    padding: 70px 0 90px 0;
    @include media-breakpoint-up(lg) {
      padding: 100px 0 140px;
    }
    h2{
      padding: 0;
    }
  }
  
  h2{
    padding: 70px 0 90px 0;
    @include media-breakpoint-up(lg) {
      padding: 100px 0 140px;
    }
  }
  
  &.green{
    background-color: #60684a;
  }

  &.create{
    @include media-breakpoint-up(lg) {
      .decor-content{
        padding: 80px 0 160px;
        h2{
          padding: 0;
        }
      }
      h2{
        padding: 80px 0 160px;
      }
      margin-bottom: -90px;
    }
  }
}

.shadow-box {
  background: $white;
  @include shadow(8);
  padding: 10px;
  @include media-breakpoint-up(lg) {
    padding: 0;
  }
}


.cookie-disclaimer{
  display: none;
  position: fixed;
  z-index: 1001;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(51, 51, 51, 0.8);
  &[data-open]{
    display: block;
    [class*=col]:not(:empty){
      background: none;
    }
  }
}

body.reg .cookie-disclaimer .content{
  margin-top: 0;
  margin-bottom: 0;
  .row{
    margin-bottom: 0;
  }
}
