$font-size: 16px;

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px white inset!important;
  &.gray {
    box-shadow: 0 0 0px 1000px $gray-200 inset!important;
  }
}

input::-ms-clear {
  display: none;
}

input{
  @include font;
}

input[type=text],
input[type=number],
input[type=password],
textarea,
select{
  &, &:focus, &:disabled{
    background: none;
    outline:0!important;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
}

input,
button,
a {
  &, &:focus, &:disabled{
    outline:0!important;
  }
}

[type=submit][disabled],
button[disabled]:not(.noti-navigation){
  cursor: default;
  color: $gray-400!important;
  border-color: $gray-250!important;
  background: $gray-250!important;
}

form{
  .form-category {
    margin-bottom: 40px;
    /* @include media-breakpoint-up(xl) {
      margin-bottom: 40px;
    } */
  }
}


label {
  display: block;
  position: relative;
  &.checkbox,
  &.radio {
    cursor: pointer;
    padding-left: 30px;
    transition: opacity 0.2s;
    .custom-checkbox,
    .custom-radio{
      position: absolute;
      top: -4px;
      left: 3px;
    }
    
    &:hover{
      p, span, strong {
        opacity: 0.7;
      }
    }
    &:active,
    &:visited{
      p, span, strong {
        opacity: 0.9;
      }
    }
  }
}

.label{
  font-size: 10px;
  color: $secondary;
  display: block;
  padding: 0 8px 8px 8px;
}

.custom-checkbox,
.custom-radio{
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  input{
    position: relative;
    z-index: 3;
    border: none;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
    & ~ i {
      position: absolute;
      z-index: 2;
      display: block;
      width: 11px;
      height: 11px;
      left: 5px;
      top: 6px;
      border: 1px solid $secondary;
    }
    &:checked ~ i {
      background: $secondary;
      &:after{
        content: '';
        display: block;
        position: absolute;
        left: 3px;
        top: -1px;
        width: 5px;
        height: 9px;
        border-bottom: 2px solid $white;
        border-right: 2px solid $white;
        transform: rotate(40deg);
      }
    }
  }
}

.custom-radio{
  input{
    & ~ i {
      border-radius: 50%;
    }
    &:checked ~ i {
      background: $secondary;
      &:after{
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        background: $secondary;
        border: 2px solid $white;
        border-radius: 50%;
      }
    }
  }
}

.form-control{
  padding: 0;
  font-size: $font-size;
  line-height: 20px;
  color: $dark;
  transition: none;
  &:focus{
    box-shadow: none;
    border-color: $secondary;
  }
}

.form-text{
  &, .char-counter{
    position: relative;
    font-size: 10px;
    margin: 0;
    padding: 5px 8px 0 8px;
    color: $gray-400;
  }
  .char-counter{
    position: absolute;
    right: 0;
    top: 0;
  }
}

input[type=text],
input[type=password],
input[type=number],
textarea{
  font-size: 16px;
  line-height: 20px;
  padding: 8px;
  border: none;
  border-bottom: 1px solid $secondary;
}

textarea.form-control{
  border: 1px solid $secondary;
  padding: 20px 8px 8px 8px;
  min-height: 100px;
  overflow: auto;
  resize: vertical;
}

.form-control:disabled, 
.form-control[readonly] {
  background-color: inherit;
  opacity: inherit;
}

.form-control[readonly] {
  background-color: inherit;
  opacity: 0.5;
}

.custom-label{
  position: relative;
  padding: 10px 0 0 0;
  &.disabled{
    opacity: 0.5;
    .label,
    input{
      cursor: default;
    }
  }
  .label{
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, 18px);
    transition: transform 300ms;
    font-size: $font-size;
    cursor: text;
    padding: 0 8px;
    user-select: none;
    &, & * {
      color: $gray-400;
    }
  };
  .decor{
    position: absolute;
    right: 8px;
    bottom: 8px;
    user-select: none;
    cursor: text;
  }
  &.filled{
    .label{
      transform: translate(0, 0);
      font-size: 10px;
      &,
      & > span,
      & > strong{
        color: $secondary;
      }
    }
  }

  &.textarea{
    .label{
      top: 13px;
    }
    &.filled{
      .label{
        height: 20px;
        line-height: 20px;
        background: $white;
        width: 95%;
        user-select: none;
        border-left: 1px solid $secondary;
        top: 11px;
      }
    }
  }
}

.custom-select {
  position: relative;
  height: 48px;
  padding-top: 10px;
  transition: opacity 0.2s;
  &:hover{
    opacity: 0.7;
  }
  &:active,
  &:visited{
    opacity: 0.9;
  }
  &.drop-down{
    background: $white;
    padding-top: 0;
    height: 38px;
    @include shadow(8);

    &[data-value="asc"],
    &[data-value="desc"] {
      .mask{
        &:before {
          content: '';
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url(/static/img/ico-arrow-down.svg) no-repeat 0 0;
          transform: translate(-26px, 0);
          margin: -10px -20px -6px 0px;
        }
      }
    }

    &[data-value="desc"] {
      .mask{
        &:before{
          transform: translate(-26px, 0) rotate(180deg)
        }
      };
    }

    .mask,
    label{
      @include font(condbold);
      transition: transform 0ms!important;
      text-align: center;
      width: 100%;
      text-indent: -10px;
      height: 38px;
      border-bottom: none;
    }
    &.filled{
      label{
        visibility: hidden;
      }
    }

  }
  &.filled{
    label,
    label > span,
    label > strong{
      font-size: 10px;
      color: $secondary;
      transform: translate(0, -15px);
    }
  }
  &.disabled{
    opacity: 0.5;
    .label,
    label > span,
    label > strong,
    input{
      cursor: default;
    }
    select{
      cursor: default;
    }
  }
  label{
    position: absolute;
    padding: 8px;
    transition: transform 300ms;
    &,
    & > span,
    & > strong {
      font-size: $font-size;
      color: $gray-400;
      user-select: none;
    }
  }
  .mask {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 38px;
    bottom: 0;
    left: 0;
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid $secondary;
    transform: translate(0, 0);
    transition: transform 300ms;
    color: $dark;
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 16px;
      right: 8px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $dark;
    }
  }
  select {
    position: relative;
    height: 38px;
    z-index: 2;
    opacity: 0!important;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    padding: 8px;
    margin-top: -1px;
    option {
      padding: 0 8px;
    }
  }

  &.no-label{
    padding-top: 0;
    height: 38px;
  }
}

.form-error{
  &:not(:empty){
    padding: 5px 8px;
  }
  p{
    color: $red;
    font-size: 10px;
  }
}

.form-group{
  margin-bottom: 25px;
  &:last-of-type{
    margin-bottom: 0;
  }
  &.is-invalid{
    label.custom-label.filled .label {
      color: $red;
    }
    input[type=text],
    input[type=password]{
      border-color: $red;
    }
  }
}

.drag-drop{
  position: relative;
  background-color: #f3f3f3;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: calc(456/1358*100) + '%';
  border: 1px dashed $gray-500;

  .file-upload{
    @include stretch();
    position: absolute;
    background: none;

    canvas{
      @include stretch();
      position: absolute;
      background: none;
    }
  }
  .mask,
  .value{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .value{
    margin-top: 35px;
    font-size: 12px;
  }
}

.file-upload{
  position: relative;
  overflow: hidden;
  .mask{
    position: relative;
    z-index: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .btn-info{
    transition: opacity 0.2s;
  }
  &:hover{
    .btn-info {
      border-color: rgba($secondary, 0.7);
    }
  }
  &:active,
  &:visited{
    .btn-info {
      border-color: rgba($secondary, 0.9);
    }
  }
  input{
    position: absolute;
    z-index: 1;
    opacity: 0;
    right: 0;
    top: 0;
    font-size: 200px;
    cursor: pointer;
  }
}

div.expert{
  display: flex;
  align-items: center;
  .avatar {
    flex: 0 1 auto;
    width: 48px;
    height: 48px;
    border: solid 2px #b7a887;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    background-color: #f3f3f3;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .info{
    flex: 1 1 auto;
    padding: 0 20px;
  }
  .close{
    flex: 0 1 auto;
  }
  .custom-select{
    margin-bottom: 5px;
    &.filled{
      label{
        display: none;
      }
    }
  }
}

.time {
  display: flex;
  flex-grow: grow;
  .form-group{
    &:first-child{
      flex: 0 1 auto;
      padding-right: 16px;
    }
    &:last-child{
      flex: 0 0 80px;
    }
  }
}

.multiselect-group {
  margin-top: 2rem;
  .multiselect-label {
    font-size: 11px;
    display: block;

    &.primary {
      font-size: 16px;
      @include font(condbold);
    }

  }
  .btn {
    margin-top: 0.5rem;
  }
}

.tagozat-select {
  display: flex;

  .close {
    flex: 0 1 auto;
    margin-left: 16px;
    margin-top: 16px;
  }

  .custom-select {
    margin-bottom: 5px;
    flex-grow: 1;
    &.filled{
      label{
        display: none;
      }
    }
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ui-datepicker{ z-index: 9999 !important;}

input[type="text"].ui-autocomplete-input.ui-autocomplete-loading {
  position: relative;
  background: white url("https://jqueryui.com/resources/demos/autocomplete/images/ui-anim_basic_16x16.gif") right center no-repeat;
}
.ui-autocomplete {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  display: none;
  min-width: 160px;
  padding: 4px 0;
  margin: 0 0 10px 25px;
  list-style: none;
  background-color: #ffffff;
  border-color: #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-width: 1px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
}

.ui-menu-item > a.ui-corner-all {
  display: block;
  padding: 3px 15px;
  clear: both;
  font-weight: normal;
  line-height: 18px;
  color: #555555;
  white-space: nowrap;
  text-decoration: none;
}

.ui-state-hover, .ui-state-active {
  color: #ffffff;
  text-decoration: none;
  background-color: #0088cc;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  background-image: none;
}
