@font-face {
  font-family: 'robotobold';
  src: url('../font/roboto-bold-webfont.woff2') format('woff2'),
       url('../font/roboto-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotoregular';
  src: url('../font/roboto-regular-webfont.woff2') format('woff2'),
       url('../font/roboto-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_condensedbold';
  src: url('../font/robotocondensed-bold-webfont.woff2') format('woff2'),
       url('../font/robotocondensed-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_condensedregular';
  src: url('../font/robotocondensed-regular-webfont.woff2') format('woff2'),
       url('../font/robotocondensed-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  @include font();
}

h1, h2, h3, h4, h5, h6,
strong, .strong {
  font-weight: normal;
  @include font(bold);
  &.cond{
    @include font(condbold);
  }
}

p{
  font-size: 16px;
}

.fw-b{
  @include font(bold);
  &.cond{
    @include font(condbold);
  }
}

.cond {
  @include font(cond);
  strong{
    @include font(condbold);
  }
}
