button{
  background: none;
  padding: 0;
  border: 0;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
}

.btn{
  display: inline-block;
  font-size: 13px;
  @include font(bold);
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 3px solid transparent;
  padding: 7px 20px;
  line-height: 20px;
  min-height: 40px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
  
  &[data-valid="false"]{
    color: $gray-400!important;
    border-color: $gray-250!important;
    background: $gray-250!important;
    cursor: default;
  }
  
  &[class*=inline-block]{
    padding-left: 20px;
    padding-right: 20px;
  }
  
  &-primary{
    color: $white;
    background: $primary;
    &:hover{
      background-color: rgba($primary, 0.7);
    }
    &:active,
    &:visited{
      background-color: rgba($primary, 0.9);
    }
  }
  
  &-secondary{
    color: $white;
    background: $secondary;
    &:hover{
      background-color: rgba($secondary, 0.7);
    }
    &:active,
    &:visited{
      background-color: rgba($secondary, 0.9);
    }
  }
  
  &-info{
    background: $white;
    color: $secondary;
    border-color: $secondary;
    &:hover{
      border-color: rgba($secondary, 0.7);
    }
    &:active,
    &:visited{
      border-color: rgba($secondary, 0.9);
    }
  }
  
  &-light{
    color: $dark;
    background: $white;
    border-color: $dark;
    &:hover{
      border-color: rgba($dark, 0.7);
    }
    &:active,
    &:visited{
      border-color: rgba($dark, 0.9);
    }
  }
  
  &-link{
    background: none;
    text-decoration: underline;
  }
  
  &.simple{
    min-width: 150px;
  }
  
  &.btn-text{
    @include font();
    font-size: 12px;
    line-height: 1.2;
    text-transform: none;
    padding: 0;
    border: none;
    min-height: auto;
  }
  
  
  &.top-right{
    margin: 20px 0;
    @include media-breakpoint-up(lg) {
      position: absolute;
      right: 12px;
      margin: 0;
    }
  }
}

[class*=col] {
  & > .btn{
    display: block;
    width: 100%;
  }
}

/* @include media-breakpoint-up(xl) {
  
}
 */
