.event-card {
  display: block;
  background-color: white;
  margin-bottom: 20px;
  
  @include media-breakpoint-up(md) {
    display: flex;
  }

  &:hover {
    .title{
      opacity: 0.5;
    }
  }

  &.expired {
    .event-date.bordered {
      border-color: #797979;
    }
  }

  .event-photo {
    @include media-breakpoint-up(md) {
      width: 344px;
      height: 232px;
      background: top center no-repeat;
      background-size: cover;
    }

    &>img {
      width: 100%;
      height: auto;
    }
  }

  .program-series {
    color: $text-alt-color;
  }

  .event-address,
  .event-organizer {
    clear: both;
    padding-top: 16px;
  }

  .event-details {
    overflow: hidden;

    .title {
      border-bottom: 1px solid rgb(208, 208, 208);
      display: block;
      font-size: 18px;
      padding-bottom: 10px;
      transition: opacity 0.5s;
    }

    .caption {
      font-size: 14px;
      color: $text-alt-color;
    }

    figure {
      float: left;
      margin: 0;
      text-align: center;
      width: 44px;
      height: 44px;
      line-height: 44px;
      margin-right: 6px;

      &>* {
        vertical-align: middle;
      }
    }

    .detail {
      float: left;
      color: $text-alt-color;
      font-size: 16px;
    }
  }

  .event-date,
  .event-square {
    text-align: center;
    margin-top: 26px;
    line-height: 40px;

    &>div {
      color: $text-alt-color;
      display: inline;
      font-size: 24px;
    }

    .year,
    .day {
      &:after {
        content: ".";
      }
    }
  }
  
  .event-square{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    width: 256px;
    height: 232px;
    border: 8px solid #cccccc;
  }
  
  a.event-square{
    font-size: 18px;
    line-height: 1.2;
    transition: opacity 0.2s;
    &:hover{
      opacity: 0.7;
    }
    @include font(condbold);
    border-color: $secondary;
    color: $secondary;
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
  
  &.empty{
    display: flex;
    @include media-breakpoint-up(lg) {
      justify-content: flex-end;  
    }
  }
}

@include media-breakpoint-up(md) {
  .event-card {
    margin-bottom: 40px;

    .event-details .title {
      border-bottom: none;
      margin-top: 10px;
      font-size: 24px;
    }

    .program-series {
      color: inherit;
    }

    .event-organizer {
      padding-top: 0;
    }

    .event-date {
      margin-top: 0;
      width: 256px;
      padding: 14px 14px 0 14px;

      &>div {
        display: block;
        font-size: 16px;
      }

      .year {
        color: inherit;
        border-bottom: 1px solid $text-alt-color;
        letter-spacing: 5px;
        font-size: 24px;

        &:after {
          content: "";
        }
      }

      .month {
        font-size: 48px;
        margin-top: 10px;
        margin-bottom: 16px;
      }

      .day {
        font-size: 64px;
        color: inherit;
        margin-bottom: 8px;

        &:after {
          content: "";
        }
      }
    }
  }
}
