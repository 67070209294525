@mixin decor-line-position($deg) {
  @for $i from 1 through 7 {
    &:nth-child(#{$i}){
      transform: translate(0, #{$i*35}px) rotate(#{$deg}deg);
      transform-origin: top left;
    }
  }
}

.decor-frame{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  user-select: none;
  overflow: hidden;
  display: none;
  opacity: 0;
  transition: opacity 0.5s;
  @include media-breakpoint-up(lg) {
    display: block;
    &.loaded{
      opacity: 1;
      .decor-lines{
        display: block;
      }
    }
  }
}

.decor-lines{
  display: none;
  @include media-breakpoint-up(lg) {
    position: absolute;
    z-index: 0;
    overflow: hidden;
    .line{
      position: absolute;
      height: 20px;
      width: 10000px;
      left: 0;
      top: 0;
      background: lightgreen;
      
    }
    
    &.eq1{
      top: 400px;
      right: 0;
      width: 800px;
      height: 4000px;
      /* background: lightblue; */
      .line{
        @include decor-line-position(45);
        background: $white;
        margin-top: -270px;
        opacity: 0.5;
      }
    }
    
    &.eq3{
      bottom: -3080px;
      right: 0;
      width: 800px;
      height: 4000px;
      /* background: lightblue; */
      .line{
        @include decor-line-position(45);
        background: $white;
        margin-top: -270px;
        opacity: 0.5;
      }
    }
    
    &.eq2{
      top: 50%;
      left: 0px;
      width: 800px;
      height: 4000px;
      /* background: lightblue; */
      .line{
        @include decor-line-position(-45);
        left: auto;
        right: 0;
        margin-top: -270px;
        transform-origin: bottom right!important;
        background: $secondary;
        opacity: 0.15;
      }
    }
  }
}

.homepage{
  .decor-frame{
    .decor-lines{
      &.eq1{
        top: 720px;
      }
    }
  }
}

.profile{
  .decor-frame{
    .decor-lines{
      &.eq1{
        top: 740px;
      }
    }
  }
}

.profile{
  .decor-frame{
    .decor-lines{
      &.eq1{
        top: 900px;
      }
    }
  }
}

.expert.profile{
  .decor-frame{
    .decor-lines{
      &.eq1{
        top: 400px;
      }
    }
  }
}

/* .debug{
  display: none;
} */
