.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-y {
  display: flex;
  align-items: center;
}

@mixin shadow($size: 4) {
  box-shadow: #{$size}px #{$size}px 0px 0px rgba(0,0,0,0.1);
}

@mixin font($type: regular) {
  $fontType: map-get((
    regular: regular,
    bold: bold,
    cond: _condensedregular,
    condbold: _condensedbold
  ), $type);
  font-family: roboto#{$fontType}, Arial, Helvetica, sans-serif;
}

.shadowed {
  box-shadow: 8px 8px 0 0 rgba(0, 0, 0, 0.1);
}

.bordered {
  border: solid 4px #b7a887;
  background-color: #ffffff;

  @include media-breakpoint-up(lg) {
    border: solid 8px #b7a887;
  }
}

@mixin stretch() {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0, 0.5);
}

@mixin triangle($type, $color, $width, $height) {
  display: inline-block;
  width: 0;
  height: 0;

  @if $type == left {
    border-top: $height solid transparent;
    border-bottom: $height solid transparent;
    border-right: $width solid $color;
  } @else if $type == right {
    border-top: $height solid transparent;
    border-bottom: $height solid transparent;
    border-left: $width solid $color;
  } @else if $type == up {
    border-left: $width solid transparent;
    border-right: $width solid transparent;
    border-bottom: $height solid $color;
  } @else {
    border-left: $width solid transparent;
    border-right: $width solid transparent;
    border-top: $height solid $color;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @for $size from 1 through 10 {
      //@debug '$size ' + $size;
      .z-index#{$infix}-#{$size} {
        z-index: #{$size}!important;
      }
      .z-index#{$infix}-#{$size+1000} {
        z-index: #{$size+1000}!important;
      }
    }
  }
}

.icon{
  position: relative;
  display: inline-block;
  &.cross{
    width: 20px;
    height: 20px;
    &:before,
    &:after{
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      display: block;
      width: 100%;
      height: 1px;
      background: $dark;
      transform-origin: left center!important;
      transform: rotate(45deg) translate(-50%, -50%);
    }
    &:before{
      transform: rotate(-45deg) translate(-50%, -50%);
    }
  }
  
  &.plus{
    width: 14px;
    height: 14px;
    border: 2px solid $dark;
    &:before,
    &:after{
      content: '';
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      width: 8px;
      height: 1px;
      background: $dark;
      transform-origin: left center;
      backface-visibility: hidden;
      transform: translate(-50%, -50%);
    }
    &:before{
      width: 1px;
      height: 8px;
      transform: translate(-50%, -50%);
    }
  }
}
