body.error {
  background: #f4f4f4;
  .body-bg{
    display: none;
  }
  &.ie{
    .error-box{
      @include media-breakpoint-up(lg) {
        height: 100vh;
      }
      form{
        margin: auto;
      }
    }
  }
  
  [class*=col]:not(:empty) {
    background: none;
  }
  
  .error-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    .logo {
      justify-content: center;
      img{
        width: 40px;
      }
      h1 {
        background: none;
        font-size: 18px;
      }
    }
    h2{
      text-align: center;
      @include font(condbold);
      
      font-size: 30px;
      @include media-breakpoint-up(lg) {
        font-size: 40px;
      }
    }
    h3{
      text-align: center;
      color: $secondary;
      @include font(condbold);
      line-height: 1;
      font-size: 60px;
      margin-bottom: 10px;
      @include media-breakpoint-up(lg) {
        font-size: 210px;
      }
    }
  }
}
