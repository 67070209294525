body.login{
  .body-bg{
    position: fixed;
    z-index: 0;
    background: transparent url(/static/img/bg-reg.jpg);
    background-size: cover;
  }
  
  &.reg{
    .content{
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  
  &.ie{
    .login-box{
      @include media-breakpoint-up(lg) {
        height: 100vh;
      }
    }
  }
  
  .login-box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    form{
      flex: 0 1 100%;
      margin: 0 12px;
    }
    @include media-breakpoint-up(lg) {
      form{
        margin: auto;
      }
    }
  }
  
  .login-content{
    padding: 20px 0;
    
    a{
      font-size: 13px;
      text-decoration: underline;
    }
    
    input[type=submit]{
      display: block;
      width: 100%;
    }
    
    .checkbox{
      margin-left: -8px;
    }
    
    @include media-breakpoint-up(lg) {
      padding: 20px 0 40px 0;
    }
  }
  
  [class*=col]:not(:empty){
    background: $white;
  }
  [class*=col].transparent{
    background: none;
    border: 10px solid $white;
  }
}
