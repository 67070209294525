.overlay{
  z-index: 1000;
  transform: translate(-100%, 0);
  visibility: hidden;
  @include stretch;
  position: absolute;
  &.standard{
    .overlay-content{
      background: $white;
      padding: 0 10px;
      @include media-breakpoint-up(lg) {
        max-width: 680px;
        padding: 0 90px;
      }
    }
  }
  
  &.overlay-open{
    transform: translate(0, 0);
    visibility: visible;
  }
  
  &[data-toggle-target="thank-you"]{
    .overlay-inner > * {
      margin-bottom: 20px;
    }
    .overlay-inner > .btn {
      margin-bottom: 0;
    }
  }
  
  .overlay-bg{
    @include stretch;
    position: absolute;
    background: rgba(0,0,0, 0.5);
  }
  .overlay-content{
    position: relative;
    z-index: 1001;
    margin: 0 12px;
    @include media-breakpoint-up(lg) {
      width: 1056px;
      max-width: 100%;
      margin: auto;
      
      &.d-inline-block{
        width: auto;
      }
    }
    
  }
  .overlay-inner{
    background: $white;
    padding: 30px 20px;
  }
  .form-group.buttons{
    display: block;
    .btn{
      display: block;
      width: 100%;
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }
    }
    
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
      .btn{
        display: inline-block;
        width: auto;
        &:first-child{
          margin-bottom: 0;
        }
      }
    }
    
  }
}

[data-toggle-target=expert-list] .overlay-content{
  background: $white;
  @include media-breakpoint-up(lg) {
    max-width: 500px;
    padding: 0 20px;
  }
}

[data-toggle-target=old-browser] .overlay-content{
  background: $white;
  @include media-breakpoint-up(lg) {
    max-width: 500px;
    padding: 0 55px;
  }
}

[data-toggle-target="sub-menu"] {
  @include media-breakpoint-up(lg) {
    position: fixed;
  }
  .overlay-content{
    width: auto;
  }
  &, .overlay-bg{
    background: none;
  }
}

[data-toggle-target="noti"]{
  @include media-breakpoint-up(lg) {
    position: fixed;
    .overlay-content{
      width: auto;
    }
    &, .overlay-bg{
      background: none;
    }
  }
}

body.not-supported-browser{
  .overlay-content{
    margin-top: 40px;
    padding: 20px;
  }
  .overlay-bg {
    background: $black;
  }
}
