body.event{
  background: $gray-200;
  form{
    position: relative;
    margin-bottom: 20px;
  }
  
}

p.desc{
  line-height: 24px;
  @include font(cond);
}

h4.sub{
  color: $secondary;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  @include font(cond);
  @include media-breakpoint-up(lg) {
    font-size: 36px;
    text-align: left;
  }
}

.event-form{
  position: relative;
  @include media-breakpoint-up(lg) {
    margin-top: -50px;
  }
}

.event-title{
  max-width: 680px;
  margin: 0 auto;
  font-size: 28px;
  @include font(condbold);
  margin-bottom: 20px;
  @include media-breakpoint-up(lg) {
    font-size: 45px;
    margin-bottom: 40px;
  }
}
  
.cover-box{
  margin-bottom: 20px;
  @include media-breakpoint-up(lg) {
    margin-bottom: 40px;
  }
}

.event-location,
.event-organizer-info,
.participants{
  @include shadow(8);
}

.event-info{
  background: $white;
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 0;
  div {
    &:first-child{
      flex: 0 0 65px;
      text-align: center;
      img{
        display: inline-block;
      }
    }
  }
  & + .event-info{
    padding-top: 0;
  }
  strong{
    font-size: 18px;
    @include font(condbold);
  }
}

.participants{
  padding: 10px;
  border: 8px solid $secondary;
  & > div {
    display: flex;
    margin-bottom: 10px;
  }
  .part-count {
    display: flex;
    flex-direction: column;
    justify-content: center;
    span, strong{
      flex: 0 1 auto;
      justify-content: center;
      display: block;
      text-align: center;
      flex-wrap: wrap;
    }
    strong{
      font-size: 40px;
    }
  }
  .part-note{
    text-align: center;
    strong{
      @include font(condbold);
      font-size: 24px;
    }
  }
  .part-edit{
    justify-content: center;
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    & > div {
      margin-bottom: 0;
    }
    .part-count {
      flex: 0 0 190px;
      height: 72px;
      border-right: 1px solid $gray-300;
    }
    .part-note{
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
      strong{
        @include font(condbold);
        font-size: 24px;
      }
    }
    .part-edit{
      flex: 0 0 280px;
    }
  }
}
  
.expert-list {
  position: relative;
  max-height: 60vh;
  @include media-breakpoint-up(lg) {
    margin-right: 0;
    .expert-item {
      margin-right: 30px;
    }
  }
}


.google-maps {
  width: 100%;
  min-width: 403px;
  min-height: 302px;
}
