$grid-gutter-width: 24px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
);

$spacer: 5px;
$spacers: (
  -5: ($spacer * -1),
  -10: ($spacer * -2),
  -15: ($spacer * -3),
  -20: ($spacer * -4),
  -25: ($spacer * -5),
  -30: ($spacer * -6),
  -35: ($spacer * -7),
  -40: ($spacer * -8),
  -50: ($spacer * -10),
  0: 0,
  5: ($spacer * 1),
  10: ($spacer * 2),
  15: ($spacer * 3),
  20: ($spacer * 4),
  25: ($spacer * 5),
  30: ($spacer * 6),
  35: ($spacer * 7),
  40: ($spacer * 8),
  50: ($spacer * 10),
  60: ($spacer * 12),
  70: ($spacer * 14),
  80: ($spacer * 16)
);

$gray-100:    #f9f9f9;
$gray-200:    #f4f4f4;
$gray-250:    #b2b2b2;
$gray-300:    #d7d7d7;
$gray-400:    #7f7f7f;
$gray-500:    #787878;
   
$gray-800:    #333333;

$light:         $gray-100 !default;
$dark:          $gray-800 !default;
$red:         #e70000;

$primary:       $gray-800;
$secondary:     #b7a887;
/* $success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800; */

$enable-rounded:false;

// Required
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

// Optional
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/bootstrap-grid";
@import "../../../node_modules/bootstrap/scss/_forms";
