body.expert.profile{
  background: $gray-200;
}

.content.expert-profile{
  margin-top: -75px;
  @include media-breakpoint-up(lg) {
    margin-top: -180px;
  }
}

.expert-card {
  background: $white;
  @include shadow(8);
  padding: 0 0 20px 0;
  @include media-breakpoint-up(lg) {
    padding: 70px 0;
  }

  .expert-picture {
    margin: 12px 12px 20px 12px;
    @include media-breakpoint-up(lg) {
      margin: 0;
    }
    .drag-drop {
      position: absolute;
      padding-top: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .value {
        opacity: 0;
        margin-top: 50px;
        color: $white;
      }
      .mask {
        display: block;
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.8);
        transition: opacity 0.3s;
        transform: none;
        div {
          padding: 15px;
          background: rgba(255, 255, 255, 1);
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          white-space: nowrap;
          p{
            text-align: center;
            @include font(condbold);
            padding-bottom: 40px;
          }
        }
      }
      &:hover {
        .value,
        .mask{
          opacity: 1;
        }
      }
      &[data-preview=""],
      &:not([data-preview]) {
        .value,
        .mask{
          opacity: 1;
        }
      }
    }
    .pic {
      position: relative;
      width: 100%;
      padding-top: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      img{
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  .expert-detail {
    padding: 10px;
    @include media-breakpoint-up(lg) {
      padding: 0;
    }
    & > div {
      position: relative;
    }
    h2 {
      font-size: 36px;
      @include font(condbold);
      text-transform: uppercase;
      padding-right: 10px;
      margin-bottom: 10px;
    }
    h3 {
      font-size: 28px;
      @include font(cond);
      color: $secondary;
    }
    .organizations {
      display: block;
      margin-bottom: 10px;
      @include media-breakpoint-up(lg) {
        display: flex;
      }
      .org-box {
        margin-bottom: 10px;
        span {
          word-break: break-word;
        }
      }
      p {
        @include font(condbold);
        padding-top: 10px;
      }
    }

    .btn{
      height: 40px;
    }
  }

  .expert-info {
    margin-bottom: 20px;
    small{
      display: block;
      font-size: 12px;
      color: $gray-400;
    }
    .status{
      font-size: 13px;
      line-height: 20px;
      @include font(bold);
      text-transform: uppercase;
      text-align: center;
      display: inline-block;
      padding: 0 20px;
      @include media-breakpoint-up(lg) {
        display: block;
        padding: 0;
      }

      &.active{
        color: $white;
        background: $secondary;
      }
      &.invited{
        color: $white;
        background: $black;
      }
    }
  }

  .expert-desc{
    &.bg-light{
      background-color: $gray-200;
      font-style: italic;
      text-align: center;
      padding: 20px 10px;
      @include media-breakpoint-up(lg) {
        padding: 40px 20px;
      }
    }
  }

  &:not([data-edit]) {
  }
}

.empty-event{
  background-color: $white;
  font-style: italic;
  text-align: center;
  padding: 40px 20px;
  @include media-breakpoint-up(lg) {
    padding: 105px 20px;
  }
}

.expert-data {
  padding: 0 12px;
  @include media-breakpoint-up(lg) {
    padding: 0;
  }

  h3{
    font-size: 28px;
    @include font(condbold);
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
  }
}
