[data-parallax] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: $dark;
  overflow: hidden;
  z-index: 0;
  transition: background-color 0.5s;
  
  &.loaded {
    background: $white;
    img{
      opacity: 1;
      transition: opacity 0.5s, transform 3.5s cubic-bezier(0.39, 0.575, 0.565, 1);
    }
  }
  
  &.reverse {
    img{
      height: 100%;
      width: auto;
    }
  }
  
  img{
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: visible;
    transition: opacity 0.5s;
    /* filter: grayscale(100%); */
    
    &[data-index="1"] {
      z-index: 1;
      transform: translate(-50%, -50%);
    }
    &[data-index="2"] {
      z-index: 2;
      transform: translate(-50%, -50%);
    }
    &[data-index="3"] {
      z-index: 3;
      transform: translate(-50%, -50%);
    }
    &[data-index="4"] {
      z-index: 4;
      transform: translate(-50%, -50%);
    }
    
    &[data-index="5"] {
      display: none;
      z-index: 0;
      filter: saturate(10);
    }
  }
  
  &[data-parallax]{
    @include media-breakpoint-down(md) {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  
  &[data-parallax=kuzdosportag]:not([data-position=login]){
    img {
      &[data-index="1"] {
        transform: translate(-50%, -40%);
      }
      &[data-index="2"] {
        transform: translate(-50%, -45%);
      }
      &[data-index="3"] {
        transform: translate(-50%, -44%);
      }
      &[data-index="4"] {
        transform: translate(-50%, -35%);
      }
    }
  }
  
  &[data-parallax=technikai]:not([data-position=login]){
    @include media-breakpoint-down(md) {
      background-image: url(/static/img/parallax/technikai/mobile.jpg);
    }
    img{
      &[data-index="3"] {
        transform: translate(-50%, -47%);
      }
    }
  }
  
  &[data-parallax=loveszet]:not([data-position=login]){
    @include media-breakpoint-down(md) {
      background-image: url(/static/img/parallax/loveszet/mobile.jpg);
    }
  }
  
  &[data-parallax=kadet]:not([data-position=login]){
    @include media-breakpoint-down(md) {
      background-image: url(/static/img/parallax/kadet/mobile.jpg);
    }
    img {
      &[data-index="5"] {
        transform: translate(-50%, -38%);
      }
      &[data-index="4"] {
        transform: translate(-50%, -45%);
      }
      &[data-index="3"] {
        transform: translate(-50%, -40%);
      }
    }
  }
  
  &[data-parallax=altalanos]:not([data-position=login]){
    @include media-breakpoint-down(md) {
      background-image: url(/static/img/parallax/altalanos/mobile.jpg);
    }
  }
  
  /* login start */
  &[data-parallax=kuzdosportag][data-position=login] {
    @include media-breakpoint-down(md) {
      background: url(/static/img/parallax/kuzdosportag/blur.jpg) center center no-repeat;
      background-size: cover;
    }
    img {
      &[data-index="1"] {
        transform: translate(-50%, -75%);
      }
      &[data-index="2"] {
        transform: translate(-50%, -50%);
      }
      &[data-index="3"] {
        transform: translate(-50%, -37%);
      }
      &[data-index="4"] {
        transform: translate(-50%, -17%);
      }
    }
  }
  
  &[data-parallax=technikai][data-position=login] {
    @include media-breakpoint-down(md) {
      background: url(/static/img/parallax/technikai/mobile.jpg) center center no-repeat;
      background-size: cover;
      filter: blur(7px);
    }
    img {
      &[data-index="1"] {
        transform: translate(-50%, -65%);
      }
      &[data-index="2"] {
        transform: translate(-50%, -50%);
      }
      &[data-index="3"] {
        transform: translate(-50%, -37%);
      }
      &[data-index="4"] {
        transform: translate(-50%, -43%);
      }
    }
  }
  
  &[data-parallax=loveszet][data-position=login] {
    @include media-breakpoint-down(md) {
      background: url(/static/img/parallax/loveszet/mobile.jpg) center center no-repeat;
      background-size: cover;
      filter: blur(7px);
    }
    img {
      &[data-index="1"] {
        transform: translate(-50%, -55%);
      }
      &[data-index="2"] {
        transform: translate(-50%, -50%);
      }
      &[data-index="3"] {
        transform: translate(-50%, -50%);
      }
      &[data-index="4"] {
        transform: translate(-50%, -48%);
      }
    }
  }
  
  &[data-parallax=kadet][data-position=login] {
    @include media-breakpoint-down(md) {
      background: url(/static/img/parallax/kadet/mobile.jpg) center center no-repeat;
      background-size: cover;
      filter: blur(7px);
    }
    img {
      &[data-index="1"] {
        transform: translate(-50%, -55%);
      }
      &[data-index="2"] {
        transform: translate(-50%, -38%);
      }
      &[data-index="3"] {
        transform: translate(-50%, -40%);
      }
      &[data-index="4"] {
        transform: translate(-50%, -28%);
      }
    }
  }
  
  &[data-parallax=altalanos][data-position=login] {
    @include media-breakpoint-down(md) {
      background: url(/static/img/parallax/altalanos/mobile.jpg) center center no-repeat;
      background-size: cover;
      filter: blur(7px);
    }
    img {
      &[data-index="1"] {
        transform: translate(-50%, -55%);
      }
      &[data-index="2"] {
        transform: translate(-50%, -38%);
      }
      &[data-index="3"] {
        transform: translate(-50%, -40%);
      }
      &[data-index="4"] {
        transform: translate(-50%, -28%);
      }
    }
  }
}

